import axios from 'axios';
export function isNumeric(n) {
    n = n?.replace(',', '.') ?? n;

    return !isNaN(parseFloat(n)) && isFinite(n);
}

export const getAxios = ()=>{
    
    axios.defaults.headers = {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',         
    };

    if(window.token != null && window.token != undefined) 
        axios.defaults.headers.Authorization = {token : `Bearer ${window.token}`};

    return axios;
}

export function resizeBase64Image(base64, newWidth, newHeight, callback) {
    const img = new Image();

    img.onload = function () {
        // Criar o canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Definir o tamanho do canvas
        canvas.width = newWidth;
        canvas.height = newHeight;

        // Desenhar a imagem no canvas redimensionado
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        // Converter o canvas para Base64
        const resizedBase64 = canvas.toDataURL();
        callback(resizedBase64);
    }; 
}