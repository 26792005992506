import { Alert } from "bootstrap";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { MModal } from "../MModal/MModal";
import { guid } from "../../../util";
import Croppie from 'croppie';
import 'croppie/croppie.css';
import { Button, Col, Row } from "react-bootstrap-v5";

export const MInputCrop = forwardRef((props, ref) => {
    const [html, setHtml] = useState(null);
    const refModal = useRef();
    const inpRef = useRef();
    useImperativeHandle(ref, () => ({
        Show: file => {
            if (file != null) openUpload(file);
            refModal.current.Show();
        },
        Get: () =>{

        }
    }));

    const croppieRef = useRef(null); 
    const [croppieInstance, setCroppieInstance] = useState(null);
    const [image, setImage] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);


    useEffect(() => {
        
        var w = props?.view?.width ?? 200;
        var h =  props?.view?.height ?? 200;
        if (croppieRef.current) {
            const croppie = new Croppie(croppieRef.current, {
                viewport: { width: w, height: h, type: 'square' },
                boundary: { width: props?.container?.width ?? w + 100, 
                            height: props?.container?.height ?? h + 100 }, 
                showZoomer: props?.zoom ??  true,
                enableOrientation: true,
                enableResize: true,
                mouseWheelZoom: 'ctrl'
            });
            setCroppieInstance(croppie);
        }


    }, []);

    const turnLeft = e => {
        croppieInstance?.rotate(-90);
    }
    const turnRight = e => {
        croppieInstance?.rotate(90);
    }

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        openUpload(file);
        refModal.current.Show();
    };

    const openUpload = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                var img = reader.result;
                setImage(img); 
                croppieInstance.bind({
                    url: img,
                    orientation: 1,
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCrop = async () => {
        if (croppieInstance) {
            const cropped = await croppieInstance.result({
                type: 'base64', 
                size: 'viewport', 
            });
            setCroppedImage(cropped);
        }
    };

    const clickOpen = async (e) => {
        setCroppedImage(null);
        inpRef.current.click();
    }

    const salvar = async (e) => {        
        props?.onClick(croppedImage);
    }
    const excluir = async (e) => {
        setCroppedImage(null);
    }


    return <div>
        <h2>{props?.title}</h2>
        <Button onClick={e => clickOpen(e)} >Selecionar Imagem</Button>
        <input type="file" style={{ display: "none" }} ref={inpRef} accept="image/*" onChange={handleImageUpload} />
        <MModal size={props?.size} saveButton={croppedImage} ref={refModal} closeText="Cancelar" saveText="Aplicar" onClickSave={() => salvar()} title="Tratamento de imagem" >
            <div style={{ display: (croppedImage ? "none" : ""), textAlign:"center" }}>
                <div ref={croppieRef} style={{ marginTop: '20px' }}></div>
                <Row>
                    <Col className='col-1 col-md-2'>
                        <Button className="btn btn-light" onClick={(event) => turnLeft()}>
                            <i class="fas fa-undo"></i>
                        </Button>
                    </Col>
                    <Col className='col-1 col-md-2'>
                        <Button className="btn btn-light" onClick={(event) => turnRight()}>
                            <i class="fas fa-redo"></i>
                        </Button>
                    </Col>
                    <Col className='col-10 col-md-6'>
                        <Button className="btn btn-primary" onClick={() => handleCrop()} >
                            Aplicar
                        </Button>
                    </Col>
                </Row>
            </div>
            {
                croppedImage && (<>
                    <div style={{textAlign : "center"}}>
                        <div style={{ marginTop: '20px' }}>
                            <h3>Imagem Final:</h3>
                            <img src={croppedImage} alt="Cropped" style={{ border: '1px solid #ddd' }} />
                        </div>
                        <Row>
                            <Col>
                                <Button className="btn-danger btn-sm" onClick={(event) => excluir()}>
                                    <i className="fa fa-trash"></i>Cancelar filtro
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </>)
            }
        </MModal>
    </div>
});