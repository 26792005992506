import { React, useEffect, useRef, useState } from 'react';
import { ValidateForm, convertFileToBase64, fire, selectItemOnInputComponent, toFormValues, waitForElement } from '../../../../util';
import { MInput } from '../../../components/MInput/MInput';
import { Button, Col, Row } from 'react-bootstrap-v5';
import { validar } from '../../services/Validacoes';
import { pesquisacep } from '../../services/ViaCep';
import { API_URL } from '../../../components/MConfig';
import { getAxios } from '../../../components/net';
import Swal from 'sweetalert2';
import { tipoEmpresas } from '../tipos/tiposJs';
import { FromFormReference } from '../../../components/MForm/MForm';
import { MModal } from '../../../components/MModal/MModal';
import { atualizarAmbiente } from '../../../../ambiente';
import { MCrop } from '../../../components/MInput/MInputCrop';

export const CadastroEmpresa = props => {

    var formRef = useRef();
    const modalExcluir = useRef();
    const [id, setId] = useState(0); // ID do revendedor
    const [usaInstricao, setUsaInstricao] = useState(true);
    const [tipoConta, setTipoConta] = useState([]);
    const [tipoCadastro, setTipoCadastro] = useState("");
    const [pixFilter, setPixFilter] = useState([]);
    const [ocultos, setOcultos] = useState([]); // Produtos selecionados (ocultos)
    const [mostraOperacao, setMostraOperacao] = useState(false);
    const [produtosComissoes, setProdutosComissoes] = useState([]); // Todos os produtos
    const [indicadorSelecionado, setIndicadorSelecionado] = useState(null);
    const [revendaIndicadora, setRevendaIndicadora] = useState(null);
    const [IndicadorId, setIndicadorId] = useState(null);
    const [GerenteIndicadorId, setGerenteIndicadorId] = useState(null);
    const [logoImagem, setLogoImagem] = useState(null);


    const tipoPessoa = [{ value: "PJ", text: "Juridica" }, { value: "PF", text: "Fisica" }];


    const modificarTipoPessoa = e => {
        var v = e === "PJ";
        window.usaInstricao = v;
        setUsaInstricao(v);
        debugger;
    };

    const selecionaBanco = e => {
        setMostraOperacao(e.codigo === 104);
    };

    const cancelar = e => {
        props.irPaginaPrincipal();
    };

    const excluirLogo = () => {
        modalExcluir.current.Show();
    }

    const confirmarExcluirLogo = () => {
        setLogoImagem(null);
    }

    const enviarLogo = l => {
        setLogoImagem(l);
    }

    const carregarProdutos = (revendaId = 0) => {
        getAxios().get(API_URL + "/produto/ListarProdutosPorRevendaId/" + revendaId).then(res => {
            const produtos = res.data;

            if (revendaId > 0) {
                const produtosSelecionados = produtos
                    .filter(p => p.selecionado)
                    .map(p => p.produtoId);

                setOcultos(produtosSelecionados);
            } else {
                setOcultos([]);
            }

            setProdutosComissoes(produtos);
        });
    };

    const validarCpfCnpj = e => {
        var res = usaInstricao ? validar.cnpj(e) : validar.cpf(e);
        if (!res) {
            var msg = (usaInstricao ? "CNPJ" : "CPF") + ' inválido!';
            window.modalAlert.Show({ title: "Atencao!", message: msg, type: "error" });
            return false;
        }
        return true;
    };

    const setConta = e => {
        e = parseInt(e);
        setTipoConta(e);
        switch (e) {
            case 4: setPixFilter('(99)99999-9999'); break;
            case 6: setPixFilter('999.999.999-99'); break;
            case 7: setPixFilter("99.999.999/9999-9"); break;
            default: setPixFilter(''); break;
        }
    };

    const oculta = (e, idx) => {
        idx = parseInt(idx);
        let c = [...ocultos];
        if (c.indexOf(idx) === -1 && e) {
            c.push(idx);
        } else {
            c = c.filter(x => x !== idx);
        }
        setOcultos(c);
    };

    const salvar = () => {
        var res = FromFormReference(formRef.current);
        res.CpfCnpj = usaInstricao ? res.Cnpj : res.Cpf;
        res.IndicadorId = IndicadorId;
        res.GerenteIndicadorId = GerenteIndicadorId;

        ValidateForm(formRef.current).then(ret => {
            if (ret.length > 0) return;
            window.showWait(true);
            res.produtos = res.produto ? res.produto.map((produtoId, i) => ({ produtoId, comissionamentoId: res.tabela[i] })) : [];
            if (!res.TipoPessoa) res.tipoPessoa = "PF";
            delete res.tabela;
            delete res.produto;

            if (id !== 0) {
                res.id = id;
                getAxios().put(API_URL + "/revendedor", res).then(ret => {
                    Swal.fire("Sucesso!", "Cadastro atualizado com sucesso!", "success");
                    atualizarAmbiente(res);
                    props.irPaginaPrincipal();
                    window.showWait(false);
                }).catch(ex => {
                    console.error(ex.response.data.errors);
                    Swal.fire("Ocorreu um erro!", ex.response.data, "error");
                    window.showWait(false);
                });
            } else {
                getAxios().post(API_URL + "/revendedor", res).then(ret => {
                    Swal.fire("Sucesso!", "Cadastro realizado com sucesso!", "success");
                    atualizarAmbiente(res);
                    props.irPaginaPrincipal();
                    window.showWait(false);
                }).catch(ex => {
                    console.error(ex.response.data.errors);
                    Swal.fire("Ocorreu um erro!", ex.response.data, "error");
                    window.showWait(false);
                });
            }
        });
    };

    const modificarTipoCadastro = (e, isReload) => {
        setTipoCadastro(e);
        carregarProdutos(0);
        debugger;
        if (!isReload)
            setUsaInstricao(e !== "Autonomo");
    };

    useEffect(() => {
        let vr = formRef.current;
        fire.register('editar_empresa', e => {
            carregarEmpresa(e, vr);
        });
    }, [formRef.current]);

    useEffect(() => {
        if (tipoCadastro === "Revenda Indicadora" || tipoCadastro === "Revenda") {
            renderIndicadorId();
            renderGerenteIndicador();
        }
    }, [tipoCadastro]);

    const carregarEmpresa = (e, vr) => {
        window.showWait(true);
        const form = vr;
        form?.reset();
        setId(0);
        if (!e) return;
        getAxios().get(API_URL + "/revendedor/" + e.id).then(res => {
            var data = res.data;

            var tipoPJ = data.tipoPessoa == "PJ";

            setUsaInstricao(tipoPJ);


            preencheFormulario(data, form);
            setId(data.id);
            carregarProdutos(data.id);
            setRevendaIndicadora(data.tipoCadastro);

            if (data.logoRevendedor) {
                setLogoImagem(data.logoRevendedor);
            }


        }).finally(() => window.showWait(false));
    };

    const waitForElementById = (id, callback, interval = 100, timeout = 500) => {
        const startTime = Date.now();
        const intervalId = setInterval(() => {
            const element = document.getElementById(id);
            if (element || Date.now() - startTime > timeout) {
                clearInterval(intervalId);
                if (element) {
                    callback(element);
                }
            }
        }, interval);
    };

    const preencheFormulario = (data, form) => {
        data = { ...data };
        data.cpf = data.cpfCnpj;
        data.cnpj = data.cpfCnpj;

        //setUsaInstricao(data.tipoPessoa == "PJ");

        toFormValues({ current: form }, data);

        if (data.indicadorId) {
            getAxios().get(API_URL + "/revendedor/" + data.indicadorId)
                .then(res => {
                    const revendaIndicadoraId = res.data.id;
                    const revendaIndicadoraName = res.data.nome;

                    waitForElementById('IndicadorId_text', (element) => {
                        debugger;
                        element.value = revendaIndicadoraName;
                        setIndicadorId(revendaIndicadoraId);
                        const IndicadorIdElement = document.getElementById('IndicadorId');

                        if (IndicadorIdElement && revendaIndicadoraId) {
                            IndicadorIdElement.value = revendaIndicadoraId;
                        } else {
                            console.error("Elemento IndicadorId ou ID não encontrado.");
                        }
                    });

                    waitForElementById('IndicadorId', (element) => {
                        if (revendaIndicadoraId) {
                            element.value = revendaIndicadoraId;
                        } else {
                            console.error("Elemento IndicadorId ou ID não encontrado.");
                        }
                    });
                })
                .catch(error => console.error('Erro ao buscar Indicador:', error));
        }

        if (data.gerenteIndicadorId) {
            getAxios().get(API_URL + "/usuario/" + data.gerenteIndicadorId)
                .then(res => {
                    const gerenteIndicadorId = res.data.id;
                    const gerenteIndicadorName = res.data.nome;

                    waitForElementById('GerenteIndicadorId_text', (element) => {
                        debugger;
                        element.value = gerenteIndicadorName;
                        setGerenteIndicadorId(gerenteIndicadorId);
                        const gerenteIndicadorIdElement = document.getElementById('GerenteIndicadorId');

                        if (gerenteIndicadorIdElement && gerenteIndicadorId) {
                            gerenteIndicadorIdElement.value = gerenteIndicadorId;
                            //selectItemOnInputComponent(gerenteIndicadorIdElement, gerenteIndicadorId); // Use an array
                        } else {
                            console.error("Elemento GerenteIndicador ou ID não encontrado.");
                        }
                    });

                    waitForElementById('GerenteIndicadorId', (element) => {
                        if (gerenteIndicadorId) {
                            element.value = gerenteIndicadorId;
                        } else {
                            console.error("Elemento gerenteIndicadorId ou ID não encontrado.");
                        }
                    });
                })
                .catch(error => console.error('Erro ao buscar GerenteIndicador:', error));
        }

        if (Array.isArray(data?.produtos)) {
            data?.produtos?.forEach(x => {
                waitForElement('produto_' + x.produtoId, el => {
                    el.checked = true;
                    el.onSelect && el.onSelect({ target: el });
                    waitForElement('comissionamento_' + x.produtoId, e => {
                        selectItemOnInputComponent(e, x.comissionamentoId); // Use an array
                    });
                });
            });
        } else {
            console.error('Produtos não encontrados ou formato inválido.');
        }

    };

    const renderIndicadorId = () => {
        if (tipoCadastro === "Revenda Indicadora" || tipoCadastro === "Revenda") {
            return (
                <Row>
                    <Col className='col-12 col-md-6'>
                        <MInput
                            id="IndicadorId"
                            name="IndicadorId"
                            type="selectSearch"
                            url={API_URL + "/revendedor/ListarRevendedores"}
                            params={v => ({ TermoDaBusca: v, TipoCadastro: "Indicador Master" })}
                            onResult={res => res?.resultado?.map(z => ({ value: z.id, text: z.nome }))}
                            title="Indicador"
                            onSelectItem={e => {
                                // Atualiza o estado com o valor selecionado
                                setIndicadorId(e.value); // Atualiza o estado

                                // Opcionalmente atualiza o campo hidden diretamente, se necessário
                                const indicadorIdElement = document.getElementById("IndicadorId");
                                if (indicadorIdElement) {
                                    indicadorIdElement.value = e.value;
                                }

                                // Carregar produtos associados ao Indicador selecionado
                                carregarProdutos(e.value);
                            }}
                            placeholder="Digite o nome do Indicador..."
                        />
                        <a href="javascript:window.open(location.href)">(+) Cadastrar novo</a>
                    </Col>
                </Row>
            );
        }
        return null;
    };

    const renderGerenteIndicador = () => {
        if (tipoCadastro === "Revenda Indicadora" || tipoCadastro === "Revenda") {
            return (
                <Row>
                    <Col className='col-12 col-md-6'>
                        <MInput id="GerenteIndicadorId" type="selectSearch"
                            url={API_URL + "/usuario/ListarUsuarios"}
                            params={v => ({ TermoDaBusca: v, PerfilId: 10 })}
                            onResult={res => res?.resultado?.map(z => ({ value: z.id, text: z.nome }))}
                            title="Gerente Indicador"
                            onSelectItem={e => setGerenteIndicadorId(e.value)}
                            placeholder="Digite o nome do Gerente indicador..." />
                    </Col>
                </Row>
            );
        }
        return null;
    };

    return <>
        <form ref={formRef}>
            <h2>Cadastro de Empresa</h2>
            <Row>
                <Col className='col-6 col-md-2'>
                    <MInput
                        type="select"
                        id="TipoCadastro"
                        onSelect={(e, r) => modificarTipoCadastro(e, r)}
                        data={revendaIndicadora ? [{ value: tipoCadastro, text: tipoCadastro }] : tipoEmpresas}
                        title="Tipo Cadastro"
                    />
                </Col>
                {tipoCadastro !== "Autonomo" &&
                    <>
                        <Col className='col-6 col-md-2'>
                            <MInput type="select" id="TipoPessoa" onSelect={e => modificarTipoPessoa(e)} data={tipoPessoa} title="Tipo Pessoa" />
                        </Col>
                    </>
                }

                <Col className={'col-8 col-md-' + (usaInstricao ? "3" : "2")}>
                    {usaInstricao &&
                        <MInput type="mask"
                            validate={e => e.string().required(`Campo Cnpj obrigatório`).test('valida cnpj', 'Cnpj inválido', validarCpfCnpj)}
                            name="Cnpj"
                            mask={"99.999.999/9999-99"}
                            title="CNPJ" />
                    }
                    {!usaInstricao &&
                        <MInput type="mask"
                            validate={e => e.string().required(`Campo Cpf obrigatório`).test('valida cpf', 'Cpf inválido', validarCpfCnpj)}
                            name="Cpf"
                            mask={"999.999.999-99"}
                            title="CPF" />
                    }

                </Col>
                {
                    usaInstricao &&
                    <>
                        <Col className='col-12 col-md-2' >
                            <MInput id="InscricaoEstadual" type="text" title="Inscrição Estadual" />
                        </Col>

                        <Col className='col-12 col-md-2' >
                            <MInput id="InscricaoMunicipal" type="text" title="Inscrição Municipal" />
                        </Col>
                    </>
                }
            </Row>
            <Row>
                <Col className="col-12 col-md-12">
                    <MInput id="Nome" type="text" title="Nome" required />
                </Col>
                <Col className="col-12 col-md-12">
                    <MInput id="NomeFantasia" type="text" title="Nome Fantasia" required />
                </Col>
                <Col className="col-12 col-md-12">
                    <MInput id="Responsavel" type="text" title="Responsável" required />
                </Col>
            </Row>
            <Row>
                <hr />
                <Col className="col-6 col-md-6">

                    {logoImagem &&
                        <>
                            <Button className="btn-danger btn-sm" onClick={(event) => excluirLogo()}>
                                <i className="fa fa-trash"></i>Excluir logo</Button>
                            <div id='logobox'>
                                <img src={logoImagem} text="Logo" />
                            </div>
                        </>
                    }

                    {!logoImagem && <>
                        <MInput id="logo" type="crop" view={{width:250,height:180}} size={2}  onClick={e => enviarLogo(e)} />
                    </>}

                    <input type="hidden" name="logoRevendedor" value={logoImagem} />
                </Col>
                <Col className="col-6 col-md-6">
                    {!logoImagem && <>
                        <div className='box'>
                            Insira uma logo com as seguintes especificações minimas: <br />
                            Altura 450 pixels e largura de 640 pixels.
                            <br/>
                            Ideal imagem .png sem fundo. 
                        </div>
                    </>
                    }
                </Col>
            </Row>
            {renderIndicadorId()}
            {renderGerenteIndicador()}
            <hr />
            <strong>PRODUTOS</strong>
            <table className='table table-striped'>
                <thead>
                    <tr>
                        <th>Produto</th>
                        <th>Comissão</th>
                    </tr>
                </thead>
                <tbody>
                    {produtosComissoes.map((x) => (
                        <tr key={x.produtoId}>
                            <td style={{ paddingTop: 25 }}>
                                <MInput
                                    type="checkbox"
                                    id={`produto_${x.produtoId}`}
                                    name="produto[]"
                                    onSelect={(e) => oculta(e.target.checked, x.produtoId)}
                                    value={x.produtoId}
                                    title={x.nomeProduto}
                                    checked={ocultos.includes(x.produtoId)} // Produtos pré-selecionados
                                />
                            </td>
                            <td>
                                {ocultos.indexOf(x.produtoId) > -1 && (
                                    <div>
                                        <MInput
                                            type="select"
                                            name="tabela[]"
                                            id={`comissionamento_${x.produtoId}`}
                                            data={x.comissoes.map((q) => ({
                                                value: q.id,
                                                text: q.nomeTabela,
                                            }))}
                                        />
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <hr />
            <Row>
                <Col className='col-6 col-md-3'>
                    <MInput
                        id="SituacaoRevendedorId"
                        type="select"
                        data={props.situacaoEmpresa}
                        title="Situação"
                        onResult={res => res.map(z => ({ value: z.id, text: z.nome }))}
                    />
                </Col>
                <Col className='col-12 col-md-9'>
                    <MInput id="ApiKey" type="text" data={props.situacaoEmpresa} title="API Key" />
                </Col>
            </Row>

            <hr />
            <div style={{ position: "fixed", bottom: "5px", height: "60px", backgroundColor: "white", textAlign: "right", width: "100%" }}>
                <div style={{ width: "100%", paddingRight: "28%" }}>
                    <div style={{ float: "right" }}>
                        <Button onClick={() => salvar()}>
                            <i className="fa fa-save"></i>
                            Salvar
                        </Button>
                        <Button onClick={e => cancelar()} className="btn-secondary">
                            Fechar
                        </Button>
                    </div>
                </div>
            </div>
            <div style={{ height: 400 }}></div>
        </form>
        <MModal id="modalExcluir" ref={modalExcluir}
            title="Excluir logotipo?"
            closeText="Não" saveButton={true}
            saveText="Confirmar"
            onClickSave={res => confirmarExcluirLogo()}  >
            Deseja realmente excluir o logotipo?
            <div className="table mt-5">
                <img src={logoImagem} className='logobox' />
            </div>
        </MModal>
    </>;
};