import React, { useState } from 'react'
import { useEffect } from 'react'
import { APP_SOCKET_URL } from '../app/components/MConfig';
export const WebsockStart = force => {

  const [iniciado,setIniciado] = useState(false);
  const execucao = () => {
    /*
    let reconnect = () => {
      window.sock = undefined;
      setTimeout(function () {        
        try{
            console.log('WSS reconexao');
            WebsockStart();
        }catch{          
        }
      }, 1000);
    }
    */
    if (!force && window.sock) return;

    /*
    if (window.localStorage?.cnf == undefined) {
      reconnect();
      return;
    }*/

    const socket = new WebSocket(APP_SOCKET_URL);
    window.sock = socket;

    socket.addEventListener("open", (event) => {
      var obj = JSON.parse(window.localStorage?.cnf);      
      socket.send(JSON.stringify({ token: obj.token }));
    });

    // Listen for messages
    socket.addEventListener("message", (event) => {
      var obj = JSON.parse(event.data);

      if (Array.isArray(obj)) {
        console.warn(obj);
        document.getElementById('msgexibidor').mensagem(obj);
      }

    });

    socket.addEventListener("close", (event) => {
      console.log('Close');      
    });


    socket.addEventListener("error", (event) => {
      console.log('Erro');
      socket.close();      
    });
  }

  useEffect(() => {
    if(iniciado) return;
    execucao();
    setIniciado(true);
  }, [])

  return <></>
}